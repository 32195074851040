import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "src/components/Loading";
import { PATH } from "src/constants/paths";
const Report = lazy(() => import("src/pages/Report"));

export const ReportRoutes = () => {
  return (
    <Routes>
      <Route
        path={PATH.REPORT}
        element={
          <Suspense fallback={<Loading />}>
            <Report />
          </Suspense>
        }
      />
    </Routes>
  );
};
