import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import { PATH } from 'src/constants/paths';
const HotTravels = lazy(() => import('src/pages/HotTravels'));

export const HotTravelsRoutes = () => {
  return (
    <Routes>
      <Route
        path={PATH.HOT_TRAVELS}
        element={<Suspense fallback={<Loading />}>
        <HotTravels />
      </Suspense>}
      />
    </Routes>
  );
};
