export const PATH = {
  HOME: '/',

  HOT_TRAVELS: '/hot-travels',
  DIXE_LIENTINH: '/thuexe-lientinh',
  XETIENCHUYEN: '/xetienchuyen',
  REPORT: '/report',
};

export const API = {
  EMPLOYEES: 'https://api.dichvuthuexelientinh.vn/api/v1/employees',
  // EMPLOYEES: 'http://api.dichvuthuexelientinh.vn/api/v1/employees',
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC60_Gw6EDpdgXvZojyyl1TaqQTtzL74wU';

// 'AIzaSyByk14tKDT-5_L1EHDfWYXUReHTEaEE8p0';

export interface IGMapsApiStatus {
  status: 'loading' | 'idle' | 'ready' | 'error';
}
