import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';

i18next.use(initReactI18next)
  .use(Backend)
  .use(new LanguageDetector(null, { lookupLocalStorage: "lang" }))
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: true,
      formatSeparator: ",",
    },
    react: {
      useSuspense: true
    },
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{lng}}.json',
      crossDomain: true
    }, 
    
    // // remove duplicate call to en-US
    load: 'languageOnly',
    lng: localStorage.getItem('lang') || "en",
  });

export default i18next;