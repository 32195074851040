import React from 'react';
import 'src/index.css';
import './variables.css';
// import 'antd/dist/antd.min.css';
import 'src/assets/scss/index.scss';
import './i18n';

import App from 'src/App/App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import { Locale } from 'antd/es/locale';
import locale from 'antd/es/date-picker/locale/en_US';

// import TagManager from 'react-gtm-module';
// const tagManagerArgs = {
//   gtmId: 'G-66EV4VNHD2'
// }
// TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider theme={{
    hashed: false,
    token: {
      colorPrimary: '#00b96b',
      borderRadius: 2,
      colorBgContainer: '#f6ffed',
    },
  }} locale={{
    locale: 'en',
    DatePickerLocale: {
      locale: 'en',
      ...enUS.DatePicker,
    }
  } as Locale}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </ConfigProvider>
);
// <React.StrictMode>
// </React.StrictMode>,

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
