import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loading } from "src/components/Loading";
import { PATH } from "src/constants/paths";
const XeLienTinh = lazy(() => import("src/pages/XeLienTinh"));

export const DiXeLienTinhRoutes = () => {
  return (
    <Routes>
      <Route
        path={PATH.DIXE_LIENTINH}
        element={
          <Suspense fallback={<Loading />}>
            <XeLienTinh />
          </Suspense>
        }
      />
    </Routes>
  );
};
