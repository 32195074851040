import { BrowserRouter } from 'react-router-dom';

import { HomeRoutes } from './HomeRoutes';
import { DiXeLienTinhRoutes } from './DiXeLienTinhRoutes';
import { XeTienChuyenRoutes } from './XeTienChuyenRoutes';
import { HotTravelsRoutes } from './HotTravelsRoutes';
import { ReportRoutes } from './ReportRoutes';

export const Routes = () => {
  return (
    <BrowserRouter>
      <HomeRoutes />
      <XeTienChuyenRoutes />
      <HotTravelsRoutes />
      <DiXeLienTinhRoutes />
      <ReportRoutes />
    </BrowserRouter>
  );
};
