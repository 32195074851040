import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Loading } from 'src/components/Loading';
import { PATH } from 'src/constants/paths';
const Home = lazy(() => import('src/pages/Home'));

export const HomeRoutes = () => {
  return (
    <Routes>
      <Route
        path={PATH.HOME}
        element={<Suspense fallback={<Loading />}>
        <Home />
      </Suspense>}
      />
    </Routes>
  );
};
